.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;

  &--panelIsOpen {
    width: calc(100% - 340px);
  }
}

.pi_ba {
  z-index: 1000001 !important;
}

.pi_5 {
  z-index: 2000002 !important;
}

.pi_4,
.pi_6 {
  z-index: 2000002 !important;
}

.ms-List-cell:hover .action-icon,
.ms-List-cell .is-selected .action-icon {
  opacity: 1;
}
.action-icon {
  opacity: 1;
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  top: 0;
  line-height: 46px;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.1s linear;
}