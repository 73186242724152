@import './color-themes';

.commonReadOnly {
  @media only screen and (max-width: 5000px) {
    [class^='gridItem'] {
      grid-column: 1 / span 3;
    }
  }

  .show-hide-column {
    .ms-DetailsHeader-cellTitle {
      justify-content: flex-end;
    }
  }
}

.readonly-form {
  padding: 20px 0;

  @include themed() {
    color: t($labelInput);
  }

  @include themed() {
    background: t($bgform);
  }

  .section-conatiner {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .label-value-wrap {
      margin-bottom: 10px;
      display: 'grid';
      grid-template-columns: '33% 66%';

      &.column {
        align-items: flex-start;
        flex-direction: column;
        margin: 0;
      }
    }

    .label {
      font-weight: 600;
      margin-right: 6px;
      white-space: nowrap;

      @include themed() {
        color: t($labelInput);
      }
    }

    .value {
      word-break: break-all;
      @include themed() {
        color: t('primarytext');
      }
    }

    .value-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .persona {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    .content-wrap {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .icon {
      width: 17px;
      height: 17px;
      padding-right: 4px;
    }
  }

  .sub-clients-table {
    font-size: 15px;

    .sub-clients-header {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      padding-left: 20px;
      font-weight: 600;

      @include themed() {
        background-color: t('neutralLight');
      }

      span {
        padding: 7px 0;
      }
    }

    .sub-clients-list {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      padding: 12px 0 12px 20px;

      @include themed() {
        border-bottom: 1px solid t('neutralLight');
      }
    }
  }

  .word-break {
    word-break: break-word;
  }
}

.header-read-only {
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 12px;
  row-gap: 20px;

  &-shimmer {
    margin: 0 auto;
    margin-top: 20px;
  }

  .container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    line-height: 18px;
    min-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include themed() {
      color: t('primarytext');
    }

    &.empty {
      color: #bab8b7;
      border-color: #bab8b7;
    }
  }

  .value {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 28px;
    font-weight: normal;
    margin-right: 6px;
    border-bottom: 1px solid;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 0.9rem;

    & > span {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      //max-width: 280px;
    }

    & > .ms-TooltipHost {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    @include themed() {
      color: t('primarytext');
      border-color: t('primarytext');
    }

    &.empty {
      color: #bab8b7;
      border-color: #bab8b7;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  .icon {
    width: 17px;
    height: 17px;
    padding-right: 7px;
  }

  //Hack to hide space showing below header section, when department modal is opened
  .ms-layer {
    display: none;
  }
}
